<template>
  <v-container>
    <v-row justify="center" align="center" class="my-2">
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="nameRu"
          item-value="id"
          :items="companies.data"
          dense
          label="Выберите компанию"
          color="cyan darken-2"
          v-model="selectedCompany"
          prepend-icon="mdi-domain"
          :loading="companiesLoading"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center py-0">
        <v-autocomplete
          outlined
          item-text="name"
          item-value="id"
          :items="companyCoreMarkets.data"
          dense
          label="Выберите элемент"
          color="cyan darken-2"
          v-model="selectedCoreMarket"
          prepend-icon="mdi-shopping-outline"
          append-outer-icon="mdi-plus"
          :loading="companyCoreMarketsLoading"
          @click:append-outer="add()"
          v-if="!addStatus"
        >
        </v-autocomplete>
        <v-text-field
          outlined
          type="text"
          dense
          label="Введите наименование"
          color="cyan darken-2"
          v-model="name"
          prepend-icon="mdi-minus"
          @click:prepend="addCancel()"
          append-outer-icon="mdi-plus"
          @click:append-outer="insert()"
          v-else
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <span class="text-h6 font-weight-bold">Ограничения CoreMarket</span>
        <v-divider class="my-2" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
        <Filterrr />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Listtt />
      </v-col>
      <v-col cols="12" md="1" lg="1" xl="1" class="text-center">
        <Actionnn />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
        <Markettt />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Filterrr from "./CoreMarketFilter/Filterrr.vue";
import Listtt from "./CoreMarketFilter/Listtt.vue";
import Markettt from "./CoreMarketFilter/Markettt.vue";
import Actionnn from "./CoreMarketFilter/Actionnn.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CoreMarket",
  components: {
    Filterrr,
    Listtt,
    Markettt,
    Actionnn,
  },
  computed: {
    ...mapGetters({
      getCompanies: "item/GET_COMPANIES",
      getCompanyCoreMarkets: "coreMarket/GET_COMPANY_CORE_MARKETS",
      getAddItem: "coreMarket/GET_ADD_ITEM",
    }),
    companies() {
      return this.getCompanies;
    },
    companyCoreMarkets() {
      return this.getCompanyCoreMarkets;
    },
    addedItem() {
      return this.getAddItem;
    },
  },
  data: () => ({
    companiesLoading: false,
    companyCoreMarketsLoading: false,
    selectedCompany: 0,
    selectedCoreMarket: 0,
    addStatus: false,
    name: null,
  }),
  methods: {
    ...mapActions({
      getCatalogsByReportTypeId: "catalog/GET_BY_REPORT_TYPE_ID",
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS_FROM_API",
      getCatalogItems: "coreMarket/GET_CATALOG_ITEMS_BY_CORE_MARKET_ID",
      getCompaniesFromAPI: "item/GET_COMPANIES_FROM_API",
      getCompanyCoreMarketsFromAPI:
        "coreMarket/GET_COMPANY_CORE_MARKETS_FROM_API",
      commitSelectedCoreMarket: "coreMarket/COMMIT_SELECTED_CORE_MARKET",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
      addItemFromApi: "coreMarket/ADD_ITEM_FROM_API",
    }),
    add() {
      this.addStatus = true;
    },
    async insert() {
      if (this.selectedCompany == 0) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red darken-2",
          text: "Выберите компанию!",
        });
        return;
      }

      if (this.name == null || this.name.length < 3) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red darken-2",
          text: "Введите коректное наименование!",
        });
        return;
      }

      await this.addItemFromApi({
        companyId: this.selectedCompany,
        name: this.name,
      });

      if (this.addedItem.succeeded) {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Элемент успешн создан!",
        });

        this.companyCoreMarketsLoading = true;
        this.getCompanyCoreMarketsFromAPI({
          companyId: this.selectedCompany,
        });
        this.companyCoreMarketsLoading = false;
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "yellow darken-2",
          text: "Ошибка при создании элемента!",
        });
      }
      this.addStatus = false;
    },
    addCancel() {
      this.addStatus = false;
    },
  },
  watch: {
    selectedCompany: function () {
      this.companyCoreMarketsLoading = true;
      this.getCompanyCoreMarketsFromAPI({
        companyId: this.selectedCompany,
      });
      this.companyCoreMarketsLoading = false;
    },
    selectedCoreMarket: function () {
      this.getCatalogItems({
        coreMarketId: this.selectedCoreMarket,
      });
      this.commitSelectedCoreMarket(this.selectedCoreMarket);
    },
  },
  async created() {
    await this.getCatalogsByReportTypeId({
      reportTypeId: 5,
    });
    await this.getCatalogRequestConfigs();

    this.companiesLoading = true;
    await this.getCompaniesFromAPI();
    this.companiesLoading = false;
  },
};
</script>