<template>
  <div>
    <v-btn-toggle class="toggle-column">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon color="#3FA0A5">mdi-arrow-right-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="moveToSelect(2)">
              <v-list-item-title>{{
                currentLanguage == "ru"
                  ? string_values.find((x) => x.value == "to_market").nameRu
                  : string_values.find((x) => x.value == "to_market").nameEn
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn @click="deleteCatalogItems()">
        <v-icon color="red">mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import { string_values } from "../../../assets/strings/string_values";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CatalogActionnnn",
  data: () => ({
    coreMarketId: null,
    string_values: string_values,
    currentLanguage: "ru",
  }),
  computed: {
    ...mapGetters({
      getSelectedCatalogItems: "catalog/GET_SELECTED_CATALOG_FILTER_ITEMS",
      getAddedCatalogItemsCount: "coreMarket/GET_ADD_CATALOG_ITEMS_COUNT",
      getSelectedDeleteCatalogItems:
        "catalog/GET_SELECTED_DELETE_CATALOG_FILTER_ITEMS",
      getDeletedCatalogItemsCount:
        "coreMarket/GET_DELETED_CATALOG_ITEMS_COUNT",
      getCatalogRequestConfigs: "config/GET_CATALOG_REQUEST_CONFIGS",
      getAtcIds: "item/GET_ATC_ID",
      getSelectedCoreMarket: "coreMarket/GET_SELECTED_CORE_MARKET",
    }),
    selectedCatalogItems() {
      return this.getSelectedCatalogItems;
    },
    addedCatalogItemsCount() {
      return this.getAddedCatalogItemsCount;
    },
    selectedDeleteCatalogItems() {
      return this.getSelectedDeleteCatalogItems;
    },
    deletedCatalogItemsCount() {
      return this.getDeletedCatalogItemsCount;
    },
    catalogRequestConfigs() {
      return this.getCatalogRequestConfigs;
    },
    atcIds() {
      return this.getAtcIds;
    },
    selectedCoreMarket() {
      return this.getSelectedCoreMarket;
    },
  },
  methods: {
    ...mapActions({
      addCatalogItems: "coreMarket/ADD_CATALOG_ITEMS_FROM_API",
      deleteCatalogItemsFromAPI: "coreMarket/DELETE_CATALOG_ITEMS_FROM_API",
      generateSnackbar: "snackbar/GENERATE_SNACKBAR",
      getIdsByATCCode: "item/GET_ID_BY_ATC_CODE",
      getCatalogItems: "coreMarket/GET_CATALOG_ITEMS_BY_CORE_MARKET_ID",
    }),
    async moveToSelect(type) {
      if (this.selectedCatalogItems.List.length > 0) {
        this.coreMarketId = this.selectedCoreMarket;
        var resultSelectedCatalogItems = this.selectedCatalogItems;
        var catalogItem = this.catalogRequestConfigs.find(
          (x) =>
            x.catalogRequestTypeId == 1001 &&
            x.catalogId == resultSelectedCatalogItems.List[0].catalogId
        );
        var coreMarketId = this.coreMarketId;
        if (catalogItem != undefined) {
          var resultStr = "";
          resultSelectedCatalogItems.List.map((x) => x.value).forEach(function (
            val
          ) {
            resultStr += catalogItem.currentCatalogID + val;
          });

          var query = catalogItem.url + catalogItem.query + resultStr;
          await this.getIdsByATCCode(query);

          var selectedItemsArr = [];
          this.atcIds.data.forEach(function (value) {
            selectedItemsArr.push({
              coreMarketId: coreMarketId,
              catalogId: catalogItem.catalogId,
              value: value,
              userReportCatalogTypeId: type,
            });
          });
          resultSelectedCatalogItems.List = selectedItemsArr;
          await this.addCatalogItems(resultSelectedCatalogItems);
        } else {
          resultSelectedCatalogItems.List = resultSelectedCatalogItems.List.map(
            function (x) {
              return {
                catalogId: x.catalogId,
                coreMarketId: coreMarketId,
                value: x.value,
                userReportCatalogTypeId: type,
              };
            }
          );
          await this.addCatalogItems(resultSelectedCatalogItems);
        }
        if (this.addedCatalogItemsCount.data > 0)
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "green",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "success_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "success_message")
                    .nameEn,
          });
        else
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "error_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "error_message")
                    .nameEn,
          });

        await this.getCatalogItems({
          coreMarketId: this.selectedCoreMarket,
        });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
    },
    async deleteCatalogItems() {
      if (this.selectedDeleteCatalogItems.length > 0) {
        await this.deleteCatalogItemsFromAPI(this.selectedDeleteCatalogItems);
        if (this.deletedCatalogItemsCount.data > 0)
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "green",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "success_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "success_message")
                    .nameEn,
          });
        else
          this.generateSnackbar({
            show: true,
            timeout: 3000,
            color: "red",
            text:
              this.currentLanguage == "ru"
                ? this.string_values.find((x) => x.value == "error_message")
                    .nameRu
                : this.string_values.find((x) => x.value == "error_message")
                    .nameEn,
          });

        await this.getCatalogItems({
          coreMarketId: this.selectedCoreMarket,
        });
      } else {
        this.generateSnackbar({
          show: true,
          timeout: 3000,
          color: "red",
          text:
            this.currentLanguage == "ru"
              ? this.string_values.find((x) => x.value == "error_message")
                  .nameRu
              : this.string_values.find((x) => x.value == "error_message")
                  .nameEn,
        });
      }
    },
  },
  mounted() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
  },
};
</script>

<style scoped>
.toggle-column {
  flex-direction: column;
}
</style>
