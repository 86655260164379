import coreMarket from '../../api/index'

const GetByCompanyId = (query) => {
    return new Promise((resolve) => {
        resolve(coreMarket.coreMarket.getByCompanyId(query))
    })
}

const getCatalogItems = (query) => {
    return new Promise((resolve) => {
        resolve(coreMarket.coreMarket.getCatalogItems(query))
    })
}

const deleteItems = (query) => {
    return new Promise((resolve) => {
        resolve(coreMarket.coreMarket.deleteItems(query))
    })
}

const addItems = (query) => {
    return new Promise((resolve) => {
        resolve(coreMarket.coreMarket.addItems(query))
    })
}

const add = (query) => {
    return new Promise((resolve) => {
        resolve(coreMarket.coreMarket.add(query))
    })
}


export default {
    namespaced: true,
    state: {
        companyCoreMarkets: [],
        selectedCoreMarket: null,
        catalogItems: [],
        deleteCatalogItemCount: null,
        addCatalogItemCount: null,
        addItem: null
    },
    getters: {
        GET_COMPANY_CORE_MARKETS(state) {
            return state.companyCoreMarkets;
        },
        GET_SELECTED_CORE_MARKET(state) {
            return state.selectedCoreMarket;
        },
        GET_CATALOG_ITEMS(state) {
            return state.catalogItems;
        },
        GET_DELETED_CATALOG_ITEMS_COUNT(state) {
            return state.deleteCatalogItemCount;
        },
        GET_ADD_CATALOG_ITEMS_COUNT(state) {
            return state.addCatalogItemCount;
        },
        GET_ADD_ITEM(state) {
            return state.addItem;
        },
    },
    mutations: {
        SET_COMPANY_CORE_MARKETS(state, payload) {
            state.companyCoreMarkets = payload;
        },
        SET_SELECTED_CORE_MARKET(state, payload) {
            state.selectedCoreMarket = payload;
        },
        SET_CATALOG_ITEMS(state, payload) {
            state.catalogItems = payload;
        },
        SET_DELETED_CATALOG_ITEMS_COUNT(state, payload) {
            state.deleteCatalogItemCount = payload;
        },
        SET_ADD_CATALOG_ITEMS_COUNT(state, payload) {
            state.addCatalogItemCount = payload;
        },
        SET_ADD_ITEM(state, payload) {
            state.addItem = payload;
        },
    },
    actions: {
        async GET_COMPANY_CORE_MARKETS_FROM_API({ commit }, payload) {
            await GetByCompanyId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_COMPANY_CORE_MARKETS', data);
                    }
                }
            )
        },
        COMMIT_SELECTED_CORE_MARKET({ commit }, payload) {
            commit('SET_SELECTED_CORE_MARKET', payload);
        },
        async GET_CATALOG_ITEMS_BY_CORE_MARKET_ID({ commit }, payload) {
            await getCatalogItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CATALOG_ITEMS', data);
                    }
                }
            )
        },
        async DELETE_CATALOG_ITEMS_FROM_API({ commit }, payload) {
            await deleteItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DELETED_CATALOG_ITEMS_COUNT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DELETED_CATALOG_ITEMS_COUNT', { data: 0 });
                })
        },
        async ADD_CATALOG_ITEMS_FROM_API({ commit }, payload) {
            await addItems(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_CATALOG_ITEMS_COUNT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ADD_CATALOG_ITEMS_COUNT', { data: 0 });
                })
        },
        async ADD_ITEM_FROM_API({ commit }, payload) {
            await add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_ITEM', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ADD_ITEM', { data: 0 });
                })
        },
    }
}
