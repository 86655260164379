
import reportInstance from './report/instance'
import catalogInstance from './catalog/instance'
import unloadInstance from './unload/instance'
import identityInstance from './identity/instance'
import cubeModule from './report/cube'
import catalogModule from './report/catalog'
import configModule from './report/config'
import itemModule from './catalog/item'
import companyModule from './catalog/company'
import filterModule from './report/filter'
import userReportModule from './report/userReport'
import unloadModule from './unload/unload'
import identityModule from './identity/identity'
import userCatalogModule from './report/userCatalog'
import coreMarketModule from './report/coreMarket'


export default {
    cube: cubeModule(reportInstance),
    catalog: catalogModule(reportInstance),
    config: configModule(reportInstance),
    filter: filterModule(reportInstance),
    item: itemModule(catalogInstance),
    userReport: userReportModule(reportInstance),
    unload: unloadModule(unloadInstance),
    identity: identityModule(identityInstance),
    company: companyModule(catalogInstance),
    userCatalog: userCatalogModule(reportInstance),
    coreMarket: coreMarketModule(reportInstance),
}
