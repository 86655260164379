export default function (instance) {
    return {
        getByCompanyId(payload) {
            return instance.get('/CoreMarket/GetByCompanyId?companyId=' + payload.companyId);
        },
        getCatalogItems(payload) {
            return instance.get('/CoreMarket/GetCatalogs?coreMarketId=' + payload.coreMarketId);
        },
        deleteItems(payload) {
            return instance.post('/CoreMarket/DeleteCatalogs', payload)
        },
        addItems(payload) {
            return instance.post('/CoreMarket/AddCatalogs', payload)
        },
        add(payload) {
            return instance.post('/CoreMarket/Add', payload)
        },
    }
}