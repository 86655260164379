<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="
        userReportsByCompanyId != null ? userReportsByCompanyId.items : []
      "
      :items-per-page="20"
      class="elevation-2"
      hide-default-footer
      :loading="loading"
      loading-text="Идет загрузка... Пожалуйста подождите"
    >
      <template slot="progress">
        <v-progress-linear
          color="light-green darken-1"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-slot:item.createDate="{ item }">
        <span>{{ new Date(item.createDate).toLocaleString() }}</span>
      </template>
    </v-data-table>
    <v-card elevation="1">
      <v-card-text class="py-0">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          color="light-green darken-1"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UserReportsByCompanyId",
  props: {
    dateStart: String,
    dateEnd: String,
    companyId: Number,
  },
  data: () => ({
    headers: [
      {
        text: "Пользователь",
        sortable: false,
        align: "center",
        value: "userName",
      },
      {
        text: "Наименование отчета",
        sortable: false,
        align: "center",
        value: "name",
      },
      {
        text: "Описание",
        sortable: false,
        align: "center",
        value: "description",
      },
      {
        text: "Дата формирования",
        sortable: false,
        align: "center",
        value: "createDate",
      },
    ],
    currentPage: 1,
    totalPages: 1,
    loading: true,
  }),
  computed: {
    ...mapGetters({
      getUserReportsByCompanyId: "unload/GET_USER_REPORT_BY_COMPANY_ID",
    }),
    userReportsByCompanyId() {
      return this.getUserReportsByCompanyId;
    },
  },
  methods: {
    ...mapActions({
      generateUserReportsByCompanyId:
        "unload/GENERATE_USER_REPORT_BY_COMPANY_ID",
    }),
    async getUserReportByCompanyIdFromAPI(isNewPage = true) {
      await this.generateUserReportsByCompanyId({
        pageNumber: this.currentPage,
        pageSize: 20,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        companyId: this.companyId,
      });
      if (!isNewPage) {
        this.totalPages = this.userReportsByCompanyId.totalPages;
        this.currentPage = this.userReportsByCompanyId.pageNumber;
      }
    },
    setLoading(state) {
      this.loading = state;
    },
  },
  watch: {
    dateStart: function () {
      this.setLoading(true);
      this.getUserReportByCompanyIdFromAPI(false);
      this.setLoading(false);
    },
    dateEnd: function () {
      this.setLoading(true);
      this.getUserReportByCompanyIdFromAPI(false);
      this.setLoading(false);
    },
    userId: function () {
      this.setLoading(true);
      this.getUserReportByCompanyIdFromAPI(false);
      this.setLoading(false);
    },
    currentPage: function () {
      this.setLoading(true);
      this.getUserReportByCompanyIdFromAPI();
      this.setLoading(false);
    },
  },
  async created() {
    this.setLoading(true);
    await this.getUserReportByCompanyIdFromAPI(false);
    this.setLoading(false);
  },
};
</script>
